import React from "react";
import { ConfirmSignUp } from "aws-amplify-react";
import AuthSignUpContainer from './AuthConfirmSignUpContainer'
import Topbar from './Topbar'

export class AuthConfirmSignUp extends ConfirmSignUp {

  constructor(props) {
    super(props);
    this._validAuthStates = ["confirmSignUp"];
  }
  showComponent(theme) {
    return (
      <>
        <Topbar/>
        <AuthSignUpContainer
          onLoading={() => super.changeState('loading')}
          onSignIn={() => super.changeState('signIn')}
          onSignedIn={() => super.changeState('signedIn')}
        />
      </>
    )
  }
}

export default AuthConfirmSignUp;