import React from "react";
import { Loading } from "aws-amplify-react";
import AuthSpinnerContainer from './AuthSpinnerContainer'
import Topbar from './Topbar'

export class AuthSpinner extends Loading {

  constructor(props) {
    super(props);
    this._validAuthStates = ["loading"];
  }

  showComponent(theme) {

    return (
      <>
        <Topbar/>
        <AuthSpinnerContainer/>
      </>
    )
  }
}

export default AuthSpinner;