import BarChartIcon from '@material-ui/icons/BarChart';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import InputIcon from '@material-ui/icons/InputOutlined';
import ListIcon from '@material-ui/icons/ListOutlined';
import SchoolIcon from '@material-ui/icons/SchoolOutlined'
import AssignmentIcon from '@material-ui/icons/AssignmentIndOutlined';
import PostAddIcon from '@material-ui/icons/PostAddOutlined';
import CreditCardIcon from '@material-ui/icons/CreditCard'

export default [
  {
    subheader: 'Menu',
    items: [
      {
        title: 'Home',
        href: '/home',
        icon: HomeIcon
      },
      {
        title: 'Dashboards',
        href: '/dashboard',
        icon: BarChartIcon,
        items: [
          {
            title: 'Referrals Created Data',
            href: '/dashboard/roosted/referral-metrics'
          },
        ]
      },
      {
        title: 'Create A Referral',
        href: '/referrals/create/select-type',
        icon: PostAddIcon
      },
      {
        title: 'Manage Referrals Created',
        href: '/manage-referrals-sent',
        icon: ListIcon
      },
      {
        title: 'License Management',
        href: '/license',
        icon: AssignmentIcon,
        items: [
          {
            title: 'Roosted Licenses',
            href: '/license/roosted'
          },
          {
            title: 'Partner Licenses',
            href: '/license/partner'
          },
        ]
      },
      {
        title: 'Continuing Education',
        href: '/continuing-education',
        icon: SchoolIcon
      },
      {
        title: 'Order Business Cards',
        href: '/business-cards',
        icon: CreditCardIcon
      },
      {
        title: 'Account Settings',
        href: '/settings',
        icon: SettingsIcon,
        items: [
          {
            title: 'Profile',
            href: '/settings/profile'
          },
          {
            title: 'Billing',
            href: '/settings/billing'
          },
        ]
      },
      {
        title: 'Sign Out',
        href: '/signout',
        icon: InputIcon
      },
    ]
  },
];
