import React from "react";
import { VerifyContact } from "aws-amplify-react";
import AuthVerifyContactContainer from './AuthVerifyContactContainer'
import Topbar from './Topbar'

export class AuthVerifyContact extends VerifyContact {

  constructor(props) {
    super(props);
    this._validAuthStates = ["verifyContact"];
  }
  showComponent(theme) {

    return (
      <>
        <Topbar/>
        <AuthVerifyContactContainer
          onSignIn={() => super.changeState('signIn')}
          onSignedIn={() => super.changeState('signedIn')}
        />
      </>
    )
  }
}

export default AuthVerifyContact;