import BarChartIcon from '@material-ui/icons/BarChart';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import InputIcon from '@material-ui/icons/InputOutlined';
// import ListIcon from '@material-ui/icons/ListOutlined';
import InboxIcon from '@material-ui/icons/InboxOutlined';
import SchoolIcon from '@material-ui/icons/SchoolOutlined'
import AssignmentIcon from '@material-ui/icons/AssignmentIndOutlined';
// import PostAddIcon from '@material-ui/icons/PostAddOutlined';

export default [
  {
    subheader: 'Menu',
    items: [
      {
        title: 'Home',
        href: '/home',
        icon: HomeIcon
      },
      {
        title: 'Dashboards',
        href: '/dashboard',
        icon: BarChartIcon,
        items: [
          // {
          //   title: 'Created Referral Data',
          //   href: '/dashboard/roosted/referral-metrics'
          // },
          {
            title: 'Referrals Received Data',
            href: '/dashboard/partner/referral-metrics'
          },
        ]
      },
      // {
      //   title: 'Create A Referral',
      //   href: '/referrals/create/select-type',
      //   icon: PostAddIcon
      // },
      // {
      //   title: 'Manage Referrals Created',
      //   href: '/manage-referrals-sent',
      //   icon: ListIcon
      // },
      {
        title: 'Manage Referrals Received',
        href: '/manage-referrals-received',
        icon: InboxIcon,
      },
      {
        title: 'License Management',
        href: '/license',
        icon: AssignmentIcon,
        items: [
          {
            title: 'Roosted Licenses',
            href: '/license/roosted'
          },
          {
            title: 'Partner Licenses',
            href: '/license/partner'
          },
        ]
      },      
      {
        title: 'Continuing Education',
        href: '/continuing-education',
        icon: SchoolIcon
      },
      {
        title: 'Account Settings',
        href: '/settings',
        icon: SettingsIcon,
        items: [
          {
            title: 'Profile',
            href: '/settings/profile'
          },
          {
            title: 'Billing',
            href: '/settings/billing'
          },
        ]
      },
      {
        title: 'Sign Out',
        href: '/signout',
        icon: InputIcon
      },
    ]
  },
];
