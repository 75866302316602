import React from "react";
import { ForgotPassword } from "aws-amplify-react";
import AuthForgotPasswordContainer from './AuthForgotPasswordContainer'
import Topbar from './Topbar'

export class AuthForgotPassword extends ForgotPassword {

  constructor(props) {
    super(props);
    this._validAuthStates = ["forgotPassword"];
  }
  showComponent(theme) {

    return (
      <>
        <Topbar/>
        <AuthForgotPasswordContainer
          onSignIn={() => super.changeState('signIn')}
        />
      </>
    )
  }
}

export default AuthForgotPassword;