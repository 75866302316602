import React from "react";
import { 
  SignIn, 
  SignUp, 
  ConfirmSignUp,
  Greetings,
  ForgotPassword,
  Loading,
  TOTPSetup,
  RequireNewPassword,
  VerifyContact,
  Authenticator,
} from "aws-amplify-react";
import App from "./App";
import { AuthSignIn } from "./views/AuthSignIn/index"
import { AuthSignUp } from "./views/AuthSignUp/index"
import { AuthSpinner } from "./views/AuthSpinner/index"
import { AuthConfirmSignUp } from "./views/AuthConfirmSignUp/index"
import { AuthForgotPassword } from "./views/AuthForgotPassword/index"
import { AuthVerifyContact } from "./views/AuthVerifyContact/index"
import awsconfig from "./aws-exports";
import Amplify from 'aws-amplify';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './theme';

import FullStory from 'react-fullstory';

//USE to get query params on auth screens
import { createBrowserHistory } from 'history';

//set the redirect UI
console.log(process.env.REACT_APP_USER_BRANCH)
const redirect = process.env.REACT_APP_USER_BRANCH === 'master' ? 'https://app.roosted.io/' : process.env.REACT_APP_USER_BRANCH === 'staging' ? 'https://staging.dtuj2j3vg7cl3.amplifyapp.com/' : 'http://localhost:3000/'
awsconfig.oauth.redirectSignIn = redirect
awsconfig.oauth.redirectSignOut = redirect
awsconfig.authenticationFlowType = 'USER_PASSWORD_AUTH'


//Configure amplify (only do this once for the app or it will cause errors)
Amplify.configure(awsconfig);

const history = createBrowserHistory();

localStorage.setItem("setUpType", history.location.search)

const AppWithAuth = props => {

//window.LOG_LEVEL = 'DEBUG';
    return (
      <div>
        {process.env.REACT_APP_USER_BRANCH === 'master' ? <FullStory org="M15AZ" /> : <React.Fragment/>}
        <ThemeProvider theme={theme}>
          <Authenticator           
            hide={[SignIn, SignUp, ConfirmSignUp, Greetings, VerifyContact, ForgotPassword, Loading]}
            authState={history.location.pathname === '/signup' ? 'signUp' : 'signIn' } 
            >
            <AuthSpinner/>
            <AuthSignIn history={history}/>
            <AuthSignUp />
            <AuthConfirmSignUp />
            <AuthForgotPassword/>
            {/* <Greetings /> */}
            <TOTPSetup/>
            <AuthVerifyContact/>
            <RequireNewPassword/>
            <App />
          </Authenticator>
        </ThemeProvider>

      </div> 
    );
}

export default AppWithAuth;