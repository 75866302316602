import React from "react";
import { SignUp } from "aws-amplify-react";
import AuthSignUpContainer from './AuthSignUpContainer'
import Topbar from './Topbar'

export class AuthSignUp extends SignUp {

  constructor(props) {
    super(props);
    this._validAuthStates = ["signUp"];
  }
  showComponent(theme) {

    return (
      <>
        <Topbar/>
        <AuthSignUpContainer
          onConfirmSignUp={() => super.changeState('confirmSignUp')}
          onSignIn={() => super.changeState('signIn')}
          onLoading={() => super.changeState('loading')}
          onSignUp={() => super.changeState('signUp')}
        />
      </>
    )
  }
}

export default AuthSignUp;