import BarChartIcon from '@material-ui/icons/BarChart';
import HomeIcon from '@material-ui/icons/HomeOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import InputIcon from '@material-ui/icons/InputOutlined';
import ListIcon from '@material-ui/icons/ListOutlined';
import AssignmentIcon from '@material-ui/icons/AssignmentIndOutlined';
import PostAddIcon from '@material-ui/icons/PostAddOutlined';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

export default [
  {
    subheader: 'Menu',
    items: [
      {
        title: 'Home',
        href: '/home',
        icon: HomeIcon
      },
      {
        title: 'Dashboards',
        href: '/dashboard',
        icon: BarChartIcon,
        items: [
          {
            title: 'Roosted Data',
            href: '/dashboard/admin/referral-metrics'
          },
        ]
      },
      {
        title: 'Create A Referral',
        href: '/referrals/create/admin/select-type',
        icon: PostAddIcon
      },
      {
        title: 'Manage Referrals',
        href: '/manage-referrals-admin',
        icon: ListIcon
      },
      {
        title: 'License Management',
        href: '/license',
        icon: AssignmentIcon,
        items: [
          {
            title: 'Roosted Licenses',
            href: '/license/admin/roosted'
          },
          {
            title: 'Partner Licenses',
            href: '/license/admin/partner'
          },
        ]
      },
      {
        title: 'User Management',
        href: '/users/admin',
        icon: SupervisorAccountIcon,
      },
      {
        title: 'Account Settings',
        href: '/settings',
        icon: SettingsIcon,
        items: [
          {
            title: 'Profile',
            href: '/settings/profile'
          },
        ]
      },
      {
        title: 'Sign Out',
        href: '/signout',
        icon: InputIcon
      },
    ]
  },
];
